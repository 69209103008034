// This file is loaded in every pages.
// See: https://www.gatsbyjs.com/docs/how-to/styling/global-css/
import '@fontsource/noto-sans-jp';

import './src/sass/ress.css';
import './src/sass/common.scss';
import './src/sass/font.scss';
import './src/sass/components.scss';
import './src/sass/noscript.scss';
import './src/sass/override-mdc.scss';
import './src/sass/style.scss';
import React from 'react';
import { RootProvider } from './src/components/RootProvider';

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0);

  return false;
};

export const wrapRootElement = ({ element }) => {
  return <RootProvider>{element}</RootProvider>;
};
