import createTheme from '@mui/material/styles/createTheme';

export const theme = createTheme({
  typography: {
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.4rem',
    },
    button: {
      fontSize: '1.4rem',
    },
  },
  palette: {
    primary: {
      main: '#2a7bb8',
    },
  },

  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#ffffff',
          boxShadow: 'none',
          borderRadius: '4em',
          paddingLeft: '2em',
          paddingRight: '2em',
          '&:active': {
            boxShadow: 'none',
          },
          '&:hover': {
            boxShadow: '0 0.2rem 0.4rem 0.2rem #00000010',
          },
        },
      },
    },
  },
});
