import React from 'react';
import { LocationProvider } from '@reach/router';
import { QueryParamProvider } from 'use-query-params';
import { ReferenceCodeProvider } from './ReferenceCodeProvider';
import { ReachAdapter } from 'use-query-params/adapters/reach';
import { theme } from '../theme/mui-theme';
import { ThemeProvider } from '@mui/material';
import { useCookies } from 'react-cookie';

export const Context = React.createContext({ env: '', hutk: '' });

export const RootProvider: React.FC = ({ children }) => {
  const [hydrated, setHydrated] = React.useState(false);
  React.useEffect(() => {
    setHydrated(true);
  }, []);

  const [cookies] = useCookies(['hubspotutk']);

  if (!hydrated) {
    return <></>;
  }

  const env =
    location.origin === 'https://www.lp.pay-per-dev.io' ||
    location.origin === 'https://www.lp.pay-per-stg.io'
      ? 'stg'
      : location.origin.includes('localhost')
      ? 'dev'
      : 'prod';

  return (
    <ThemeProvider theme={theme}>
      <LocationProvider>
        <Context.Provider value={{ env: env, hutk: cookies.hubspotutk }}>
          <QueryParamProvider adapter={ReachAdapter}>
            <ReferenceCodeProvider>{children}</ReferenceCodeProvider>
          </QueryParamProvider>
        </Context.Provider>
      </LocationProvider>
    </ThemeProvider>
  );
};
