import React, { useEffect } from 'react';
import { useQueryParamString } from 'react-use-query-param-string';

// use-query-paramはQueryParamProvider以下でしか実行できないのでRootProviderではなく別のContextとしている
export const ReferenceCodeProvider: React.FC = ({ children }: any) => {
  const [campaign, _setCampaignValue, campaignInitialized] =
    useQueryParamString('campaign', '');
  const [zuuCode, _setZuuValue, zuuInitialized] = useQueryParamString(
    'zuu_code',
    ''
  );
  const [utmSource, _setUtmSource, utmSourceInitialized] = useQueryParamString(
    'utm_source',
    ''
  );
  const [utmMedium, _setUtmMedium, utmMediumInitialized] = useQueryParamString(
    'utm_medium',
    ''
  );
  const [utmCampaign, _setUtmCampaign, utmCampaignInitialized] =
    useQueryParamString('utm_campaign', '');
  const [utmTerm, _setUtmTerm, utmTermInitialized] = useQueryParamString(
    'utm_term',
    ''
  );
  const [utmContent, _setUtmContent, utmContentInitialized] =
    useQueryParamString('utm_content', '');

  useEffect(() => {
    if (campaign !== '' && campaignInitialized) {
      sessionStorage.setItem('campaign', campaign);
    }
    if (zuuCode !== '' && zuuInitialized) {
      sessionStorage.setItem('zuu_code', zuuCode);
    }
    if (utmSource !== '' && utmSourceInitialized) {
      sessionStorage.setItem('utm_source', utmSource);
    }
    if (utmMedium !== '' && utmMediumInitialized) {
      sessionStorage.setItem('utm_medium', utmMedium);
    }
    if (utmCampaign !== '' && utmCampaignInitialized) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }
    if (utmTerm !== '' && utmTermInitialized) {
      sessionStorage.setItem('utm_term', utmTerm);
    }
    if (utmContent !== '' && utmContentInitialized) {
      sessionStorage.setItem('utm_content', utmContent);
    }
  }, [
    campaign,
    zuuCode,
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
  ]);
  return <>{children}</>;
};
